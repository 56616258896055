import { HTMLButtonProps } from "@/types/props";
import { FC } from "react";
import styled from "styled-components";

/* Navbar */

export const NavbarContainer = styled.div.attrs({
  className: "navbar bg-base-100 sticky top-0",
})`
  font-family: "Bitter";
`;

export const NavbarMenuAnchor = styled.a`
  font-family: "Bitter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #050517;

  :active {
    background-color: #050517;
    color: white;
  }
`;

export const NavbarLogo = styled.a.attrs({
  className: "ml-10",
})`
  min-width: 115px;
  height: auto;
  width: 100%;
`;

export const NavbarSignInButton = styled.button.attrs({
  className: "btn mr-3 py-1 px-6",
})`
  min-height: unset;
  height: 100%;

  font-family: "Bitter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-transform: capitalize;

  display: flex;
  align-items: center;
  text-align: center;

  background-color: white;
  color: #050517;
  border-radius: 22px;
  border-color: transparent;

  :hover {
    color: white;
    border-color: transparent;
  }
`;

export const NavbarSignUpButton = styled.button.attrs({
  className: "btn mr-10 py-1 px-6",
})`
  min-height: unset;
  height: 100%;

  font-family: "Bitter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-transform: capitalize;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: #0d56ea;
  border-radius: 22px;
  border-color: transparent;

  :hover {
    background-color: #0b4dd2;
    border-color: transparent;
  }
`;

/* Header */

export const HeaderContainer = styled.header.attrs({
  className:
    "bg-base-200 flex shrink-0 flex-row justify-center items-center py-3",
})`
  font-family: "Bitter";
  background-color: white;
`;

export const HeaderCenteredItems = styled.div.attrs({
  className: "flex flex-col justify-center px-3 md:px-6",
})`
  align-items: center;
`;

export const HeaderLogo = styled.div.attrs({
  className: "logo",
})`
  flex: 0 0 auto;
  max-width: 200px;
  min-width: 100px;
  width: 100%;
  height: auto;
  display: flex;
`;

export const HeaderTitle = styled.h1.attrs({
  className: "text-md md:text-2xl font-bold text-left",
})`
  font-family: Roboto, sans-serif;
  font-weight: 900;
  letter-spacing: normal;
  text-align: center;
  color: #322036;
  text-transform: uppercase !important;
  line-height: 1.34;
`;

export const HeaderSubtitle = styled.p.attrs({
  className: "pb-4 text-xs md:text-md text-left",
})`
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.34;
  letter-spacing: normal;
  text-align: center;
  color: #322036;
  text-transform: uppercase !important;
`;

/* Upload And Crop Container */
export const UploadAndCrop = styled.main.attrs({
  className: "flex flex-col justify-center items-center",
})`
  background: #322036;
  padding: 0 10%;
  flex: 1 0 auto;
  padding-top: 50px;
  padding-bottom: 70px;
`;

export const UploadTitle = styled.p.attrs({
  className: "text-center text-2xl md:text-4xl items-center my-4",
})`
  font-family: Roboto, sans-serif;
  color: white;
  font-weight: 900;
  line-height: 1.34;
`;

export const UploadSubitle = styled.p.attrs({
  className: "text-center my-6 text-md md:text-xl",
})`
  font-family: Roboto, sans-serif;
  color: #999;
  font-weight: 400;
  line-height: 1.34;
`;

export const PrintText = styled.p.attrs({
  className:
    "text-center transition text-normal text-white my-6 text-md md:text-xl",
})``;

export const PrintsBtn: FC<HTMLButtonProps> = ({ className, ...props }) => (
  <button
    type="button"
    className={`btn
      normal-case
      text-center
      w-full
      max-w-[240px]
      md:max-w-[300px]
      border
      text-white
      font-normal
      rounded-lg
      border-white
      text-md
      md:text-xl
      bg-transparent
      p-2.5
      m-2.5
      cursor-pointer
      hover:border-gray-200
      hover:text-gray-50
      hover:bg-darkish-primary-blue
      disabled:border-gray-300
      disabled:text-gray-300
      disabled:cursor-not-allowed
      disabled:bg-darkish-primary-blue
      disabled:hover:border-gray-300
      disabled:hover:text-gray-300
      disabled:hover:bg-darkish-primary-blue
      ${className}
    `}
    {...props}
  />
);

export const CTAButton: FC<HTMLButtonProps> = ({ className, ...props }) => (
  <PrintsBtn
    className={`bg-light-blurple
      border-light-blurple
      hover:border-blurple
      hover:text-gray-50
      hover:bg-blurple
      disabled:border-dark-blurple
      disabled:text-gray-300
      disabled:cursor-not-allowed
      disabled:bg-dark-blurple
      disabled:hover:border-dark-blurple
      disabled:hover:text-gray-300
      disabled:hover:bg-dark-blurple
      ${className}
    `}
    {...props}
  />
);

/* Footer Container */

export const FooterContainer = styled.footer.attrs({
  className: "shrink-0 footer p-5",
})`
  background: white;
  color: black;
  display: flex;
  justify-content: center;
`;

export const FooterLogo = styled.div.attrs({
  className: "logo px-3 max-w-[100px] md:max-w-[200px]",
})`
  flex: 0 0 auto;
  width: 100%;
  display: flex;
`;

export const FooterTitle = styled.p.attrs({
  className: "normal-case px-3 text-xs md:text-base",
})`
  font-weight: 900;
  color: #322036;
  font-family: Roboto, sans-serif;
`;

export const FooterCopyright = styled.p.attrs({
  className: "px-3 text-xs md:text-base",
})`
  color: #322036;
  font-family: Roboto, sans-serif;
`;
