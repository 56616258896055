import {
  FooterContainer,
  FooterLogo,
  FooterTitle,
  FooterCopyright,
} from "../styles/styles";

import logo from "../assets/images/fgjtam.png";

const Footer = () => {
  return (
    <FooterContainer>
      <div className="flex items-center justify-center">
        {/* <FooterLogo>
          <img
            src={logo}
            alt="Logo de la Fiscalía General de Justicia del Estado de Tamaulipas"
          />
        </FooterLogo> */}
        {/* <FooterTitle>Convertidor de huellas dactilares</FooterTitle> */}
        {/* <FooterCopyright>
          Aplicación implementada por{" "}
          <span className="font-semibold">Data Cívica</span> con el apoyo de la{" "}
          <span className="font-semibold">
            Fiscalía Especializada en la Investigación de los Delitos de
            Desaparición Forzada de Personas de la Fiscalía General de Justicia
            del Estado de Tamaulipas
          </span>
          , <span className="font-semibold">Comisión Nacional de Búsqueda</span>{" "}
          y{" "}
          <span className="font-semibold">
            Fondo de Población de las Naciones Unidas
          </span>
        </FooterCopyright> */}
      </div>
    </FooterContainer>
  );
};

export default Footer;
